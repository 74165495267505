import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Builder from "./Builder";
import Dashboard from "./Dashboard";

import DocsPage from "./docs/DocsPage";
import { LayoutSplashScreen } from "../../../_metronic";


const GoogleMaterialPage = lazy(() =>
  import("./google-material/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./react-bootstrap/ReactBootstrapPage")
);

const Account_Name = lazy(() =>
  import("./Customer_Lookup/Account_Name")
);

const Service_Address = lazy(() =>
  import("./Customer_Lookup/Service_Address")
);

const View_Customer = lazy(() =>
  import("./Customer_Lookup/View_Customers")
);


const Contacts = lazy(() =>
  import("./Customer_Lookup/account_details/contacts/contacts")
);
const EditContacts = lazy(() =>
  import("./Customer_Lookup/account_details/contacts/editContactPage")
);

const Phone_Number = lazy(() =>
  import("./Customer_Lookup/Phone_Number")
);

const Account_Number = lazy(() =>
  import("./Customer_Lookup/Account_Number")
);

const ExistingCustomerQuote = lazy(() =>
  import("./Quotes/ExistingCustomerQuote/ExistingCustomerQuote")
);
const ViewQuotes = lazy(() =>
  import("./Quotes/ViewQuotes/ViewQuotes")
);
const NewCustomerQuote = lazy(() =>
  import("./Quotes/NewCustomerQuote/NewCustomerQuote")
);

const ViewOrders = lazy(() =>
  import("./Orders/ViewOrders/ViewOrders")
);

const PendingCommissions = lazy(() =>
  import("./Reports/PendingCommissions/PendingCommissions")
);

const Payouts = lazy(() =>
  import("./Reports/Payouts/Payouts")
);



const Account_Details = lazy(() =>
  import("./Customer_Lookup/account_details/account_details")
);

const History = lazy(() =>
  import("./Customer_Lookup/account_details/account_info/history")
);
const Quotes = lazy(() =>
  import("./Customer_Lookup/account_details/account_info/quotes")
);
const Orders = lazy(() =>
  import("./Customer_Lookup/account_details/account_info/orders")
);
const Invoices = lazy(() =>
  import("./Customer_Lookup/account_details/account_info/invoices")
);
const Payments = lazy(() =>
  import("./Customer_Lookup/account_details/account_info/payments")
);

const Products = lazy(() =>
  import("./Customer_Lookup/account_details/account_info/products")
);

const Orderitems = lazy(() =>
  import("./Customer_Lookup/account_details/account_info/orderitems")
);

const Myprofile = lazy(() =>
  import("./Menu_User/myprofile")
);

const New_Quote = lazy(() =>
  import("./Customer_Lookup/account_details/account_info/newquote/newquote")
);
const AddNewNRC = lazy(() =>
  import("./Customer_Lookup/account_details/account_info/newquote/addnewNRC")
)
const AddnNewService = lazy(() =>
  import("./Customer_Lookup/account_details/account_info/newquote/addnewservice")
)

const Newquote = lazy(() =>
  import("./../submenu/newquote")
);

const EditAccount = lazy(() =>
  import("./Customer_Lookup/account_details/editaccount")
);

const Prueba = lazy(() =>
  import("./prueba")
);


export default function HomePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
          
        }

        <Route path="/builder" component={Builder} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/Account_Name" component={Account_Name} /> 
        <Route path="/Service_Address" component={Service_Address} />
        <Route path="/Phone_Number" component={Phone_Number} />
        <Route path="/Account_Number" component={Account_Number} />
        <Route path="/View_Customer" component={View_Customer} />
      
      
       {/* ///////////////////// Quotes   */}
       <Route path="/ExistingCustomerQuote" component={ExistingCustomerQuote} />
       <Route path="/ViewQuotes" component={ViewQuotes} />
       <Route path="/NewCustomerQuote" component={NewCustomerQuote} />

        {/* ///////////////////// Reports   */}
       <Route path="/PendingCommissions" component={PendingCommissions} />
       <Route path="/Payouts" component={Payouts} />


       {/* /////////////////////  Orders  */}
       <Route path="/ViewOrders" component={ViewOrders} />



        


       
        <Route path="/Account_Details" component={Account_Details} />
        <Route path="/products" component={Products} />
        <Route path="/quotes" component={Quotes} />
        <Route path="/orders" component={Orders} />
        <Route path="/invoices" component={Invoices} />
        <Route path="/payments" component={Payments} />
        <Route path="/history" component={History} />
        <Route path="/orderitems" component={Orderitems} />
        <Route path="/myprofile" component={Myprofile} /> 
        <Route path="/newquote" component={Newquote} /> 
        <Route path="/editAccount" component={EditAccount} />    
        <Route path="/new_quote" component={New_Quote} />    
        <Route path="/addnewNRC" component={AddNewNRC} />    
        <Route path="/addnnewservice" component={AddnNewService} />    
        <Route path="/Contacts" component={Contacts} />    

        
        {/* <Route path="/editContacts" component={EditContacts} />     */}
        <Route path="/prueba" component={Prueba} />    
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/docs" component={DocsPage} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
