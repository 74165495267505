import React from "react" 
import { Modal, Button, Table } from "react-bootstrap"
export default class ModalCustomer extends React.Component{ 
    constructor(props){
        super(props)
    }


    render() {

      return ( <Modal responsive="true" size="xl" centered show={this.props.show} onHide={this.props.offModal}>
          <Modal.Header closeButton>
            <Modal.Title>Existing Customer Quote</Modal.Title>
          </Modal.Header>
          <Modal.Body>
   
          
           {this.props.data.length === 0 ? <div style={{textAlign: "center"}}> 
            { this.props.loading && <>You do not have accounts with this agent </> } </div>  :   <Table>
                  <thead>
                  <tr>
                      <th>Name</th>
                      <th>Account Number</th>
                      <th>Status</th>
                      </tr>
                  </thead>
                  <tbody>
                       
                          {this.props.data.map((element, i) =>
                              <tr  key={i}>
                              <td style={{ display: "flex", alignItems: "center" }}>
                                {this.props.data.length !== 0 && <input onChange={this.props.changeRadio} className="mr-2" type="radio" name="select" id={element.pk} />} <label style={{ marginBottom: "0" }} htmlFor={element.pk}> {element.name}</label>
                              </td>
                              <td>{element.accountnumber}</td>
                              <td>{element.status}</td>
                          </tr>)}
                     
                  </tbody>

              </Table> }            
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.offModal}>
            Close
            </Button>
          {this.props.data.length !== 0 &&
            <Button disabled={!this.props.selectRadio} variant="primary" onClick={this.props.OpenUrl}>
              Open
            </Button>}
          
          </Modal.Footer>
        </Modal> )
  }

}
