export default {
  header: {
    self: {},
    items: [
      {
        title: "Dashboards",
        root: false,
        alignment: "left",
        page: "dashboard",
        translate: "MENU.DASHBOARD",
      },
      {
        title: "Customer Lookup",
        root: true,
        alignment: "left",
        page: "View_Customer",
        toggle: "click",
        translate: "MENU.Customer_Lookup",
        submenu: [
          // {
          //   title: "Add Customers",
          //   bullet: "dot",
          //   page: "Phone_Number",
          // },
          {
            title: "View Customers",
            bullet: "dot",
            page: "View_Customer",
          },
        ],
      },
      {
        title: "Quotes",
        root: true,
        alignment: "left",
        page: "ViewQuotes",
        toggle: "click",
        translate: "MENU.Quotes",
        submenu: [
          {
            title: "New Customer Quote",
            bullet: "dot",
            page: "Newquote",
          },
          {
            title: "View Quotes",
            bullet: "dot",
            page: "ViewQuotes",
          },
        ],
      },
      {
        title: "Orders",
        root: true,
        alignment: "left",
        page: "ViewOrders",
        toggle: "click",
        translate: "MENU.Orders",
        submenu: [
          {
            title: "View Orders",
            bullet: "dot",
            page: "ViewOrders",
          },
        ],
      },
      {
        title: "Reports",
        root: true,
        alignment: "left",
        page: "Commissions",
        toggle: "click",
        translate: "MENU.Reports",
        submenu: [
          {
            title: "Pending Commissions",
            bullet: "dot",
            page: "PendingCommissions",
          },
          {
            title: "Payouts",
            bullet: "dot",
            page: "Payouts",
          },
        ],
      },
    ],
  },
  aside: {
    self: {},
    items: [
      {
        title: "Dashboard",
        root: true,
        icon: "flaticon2-architecture-and-city",
        page: "dashboard",
        translate: "MENU.DASHBOARD",
        bullet: "dot",
      },
      {
        title: "Customer Lookup",
        root: true,
        page: "Account_Name",
        translate: "MENU.Customer_Lookup",
        submenu: [
          {
            title: "Account details",
            root: true,
            page: "account_details",
            translate: "MENU.account_details",
          },
          {
            title: "Account details",
            root: true,
            page: "account_details",
            translate: "MENU.account_details",
            submenu: [
              {
                title: "Products",
                page: "products",
              },
              {
                title: "Quotes",
                page: "quotes",
              },
              {
                title: "Quotes",
                page: "quotes",
                submenu: [
                  {
                    title: "New Quote",
                    page: "new_quote",
                  },
                ],
              },
              {
                title: "Edit account",
                page: "editAccount",
              },
              {
                title: "Orders",
                page: "orders",
              },

              {
                title: "Orders",
                page: "orders",
                submenu: [
                  {
                    title: "Orders Items",
                    page: "orderitems",
                  },
                ],
              },
              {
                title: "Invoices",
                page: "invoices",
              },
              {
                title: "Payments",
                page: "payments",
              },
              {
                title: "History",
                page: "history",
              },
              {
                title: "Contacts",
                page: "contacts",
              },
            ],
          },
          {
            title: "My Profile",
            root: true,
            page: "myprofile",
            translate: "MENU.myprofile",
          },
          {
            title: "My Profile",
            root: true,
            page: "myprofile",
            translate: "MENU.myprofile",
            submenu: [],
          },
          {
            title: "New Quote",
            root: true,
            page: "Newquote",
            translate: "MENU.Newquote",
          },
          {
            title: "New Quote",
            root: true,
            page: "Newquote",
            translate: "MENU.Newquote",
            submenu: [],
          },
        ],
      },
    ],
  },
};
