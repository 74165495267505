import axios from "axios";

//export const LOGIN_URL = "api/auth/login";
// const urlBase =  "https://api.itpscorp.com/crmui";
const json = require("../../config.json");
const urlBase = json.urlBase;
// const urlBase =  "http://api.itpscorp.com/dev/crmui"; 

// export const LOGIN_URL = "http://api.itpscorp.com/dev/crmui/auth/login";
export const LOGIN_URL = urlBase + "/auth/login";

export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = urlBase + "/auth/reset-password?email={{username}}";


export const ME_URL = "../";
//export const ME_URL = "../home/Dashboard";

export function login(email, pass) {

  return axios.post(LOGIN_URL,{
      username: email,
      password: pass
    })
}
// export function login(email, password) {
//     return axios.post(LOGIN_URL, { email,password });

// }
  //return axios.post(LOGIN_URL, { email,password });
  // alert("por aqui paso");

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.get(urlBase +"/auth/reset-password?email="+email, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}