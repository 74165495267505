import React from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import interceptor from "./petition_interceptor";

export default function petition_user(key, val) {
  const json = require("../../../../../config.json");
  const urlBase = json.urlBase;
  const token = JSON.parse(localStorage.getItem("persist:demo4-auth"));
  console.log(token);
  var petition_get;
  // var urlBase = "http://api.itpscorp.com/dev/crmui";
  // var urlBase = "https://api.itpscorp.com/crmui";

  // console.log(json.urlBase);

  // if (token.authToken) {
  //   // console.log(token);
  if (key === "services") {
    petition_get = urlBase + "/services?account_id=" + val;
    // "http://api.itpscorp.com/dev/crmui/services?account_id=" + val;
  } else if (key === "invoices") {
    petition_get = urlBase + "/invoices?account_id=" + val;
    // "http://api.itpscorp.com/dev/crmui/invoices?account_id=" + val;
  } else if (key === "orders") {
    petition_get = urlBase + "/orders?account_id=" + val;
    // "http://api.itpscorp.com/dev/crmui/orders?account_id=" + val;
  } else if (key === "viewOrders") {
    petition_get = urlBase + "/orders";
    // "http://api.itpscorp.com/dev/crmui/orders?account_id=" + val;
  } else if (key === "viewOrders-Search") {
    petition_get = urlBase + "/orders" + val;
    // "http://api.itpscorp.com/dev/crmui/orders?account_id=" + val;
  } else if (key === "quotes") {
    petition_get = urlBase + "/quotes?account_id=" + val;
    // "http://api.itpscorp.com/dev/crmui/quotes?account_id=" + val;
  } else if (key === "viewQuotes") {
    petition_get = urlBase + "/quotes";
    // "http://api.itpscorp.com/dev/crmui/quotes?account_id=" + val;
  } else if (key === "viewQuotes-Search") {
    petition_get = urlBase + "/quotes" + val;
    // "http://api.itpscorp.com/dev/crmui/quotes?account_id=" + val;
  } else if (key === "orderitems") {
    // console.log(val);
    petition_get = urlBase + "/orderitems?order_id=" + val;
    // "http://api.itpscorp.com/dev/crmui/orderitems?order_id=" + val;
  } else if (key === "products") {
    if (val === "") {
      petition_get = urlBase + "/products";
    } else {
      petition_get = urlBase + "/products/" + val;
    }
    // "http://api.itpscorp.com/dev/crmui/products/" + val;
  } else if (key === "provision") {
    petition_get = urlBase + "/orderitems/" + val + "/provision";
    // "http://api.itpscorp.com/dev/crmui/orderitems/"+ val+"/provision";
    // console.log("uno");
  } else if (key === "complete") {
    petition_get = urlBase + "/orderitems/" + val + "/complete";
    // "http://api.itpscorp.com/dev/crmui/orderitems/"+ val+"/complete";
    // console.log("dos");
  } else if (key === "profile") {
    petition_get = urlBase + "/profile";
    // "https://api.itpscorp.com/dev/crmui/profile";
  } else if (key === "viewAccounts") {
    petition_get = urlBase + "/accounts";
    // "http://api.itpscorp.com/dev/crmui/agents/" + val;
  } else if (key === "viewAccounts-Search") {
    petition_get = urlBase + "/accounts" + val;
    // "http://api.itpscorp.com/dev/crmui/agents/" + val;
  } else if (key === "agents") {
    petition_get = urlBase + "/agents/" + val;
    // "http://api.itpscorp.com/dev/crmui/agents/" + val;
  } else if (key === "agentsFilter") {
    petition_get = urlBase + "/agents" + val;
    // "http://api.itpscorp.com/dev/crmui/agents/" + val;
  } else if (key === "agentsall") {
    petition_get = urlBase + "/agents";
    // "http://api.itpscorp.com/dev/crmui/agents";
  } else if (key === "payments") {
    petition_get = urlBase + "/payments" + "?account_id=" + val;
    // "http://api.itpscorp.com/dev/crmui/agents";
  } else if (key === "contacts") {
    petition_get = urlBase + "/contacts?account_id=" + val;
    // "http://api.itpscorp.com/dev/crmui/agents";
  } else if (key === "allContacts") {
    petition_get = urlBase + "/contacts" + val;
    // "http://api.itpscorp.com/dev/crmui/agents";
  } else if (key === "reset-password") {
    petition_get = urlBase + "/contacts/" + val + "/reset-password";
    // "http://api.itpscorp.com/dev/crmui/agents";
  } else if (key === "PDF-Invoices") {
    petition_get = urlBase + "/invoices/" + val + "/pdf";
    // "http://api.itpscorp.com/dev/crmui/agents";
  } else if (key === "Form477Generator") {
    petition_get = urlBase + "/service-address/generate-fcc-477";
    // "http://api.itpscorp.com/dev/crmui/agents";
  } else if (key === "service-address") {
    petition_get = urlBase + "/service-address?search=" + val;
    // "http://api.itpscorp.com/dev/crmui/agents";
  } else if (key === "productServices") {
    petition_get = urlBase + "/products?service_address_id=" + val;
  } else if (key === "service-address-quote") {
    petition_get = urlBase + "/service-address/" + val;
    // "http://api.itpscorp.com/dev/crmui/agents";
  } else if (key === "allProducts") {
    petition_get = urlBase + "/products";
    // "http://api.itpscorp.com/dev/crmui/agents";
  } else if (key === "history") {
    petition_get =
      urlBase + "/history?account_id=" + val + "&" + "order_by=created";
    // "http://api.itpscorp.com/dev/crmui/agents";
  } else if (key === "quoteitems?quote_id") {
    petition_get = urlBase + "/quoteitems?quote_id=" + val;
    console.log(petition_get);
    // "http://api.itpscorp.com/dev/crmui/agents";
  } else if (key === "quoteId") {
    petition_get = urlBase + "/quotes/" + val;
    // "http://api.itpscorp.com/dev/crmui/agents";
  } else if (key === "billingPayments") {
    petition_get =
      urlBase +
      "/dashboard/billing/payments?start_year=" +
      val.year1 +
      "&end_year=" +
      val.year2;
    // "http://api.itpscorp.com/dev/crmui/agents";
  } else if (key === "lateAccounts") {
    petition_get = urlBase + "/dashboard/billing/late-accounts";
    // "http://api.itpscorp.com/dev/crmui/agents";
  } else if (key === "product-keys") {
    petition_get = urlBase + "/product-keys";
  } else if (key === "service-address-detail") {
    petition_get = urlBase + "/service-address/" + val;
  } else if (key === "refresh") {
    petition_get = urlBase + "/auth/refresh";
  } else if (key === "tickets") {
    petition_get = urlBase + "/tickets" + val;
  } else if (key === "ticket-events") {
    petition_get = urlBase + "/ticket-events?ticket_id=" + val;
  } else if (key === "users") {
    petition_get = urlBase + "/users";
  } else if (key === "accountsall") {
    petition_get = urlBase + "/accounts";
  } else if (key === "accountsall-search") {
    petition_get = urlBase + "/accounts" + val;
  } else if (key === "users-search") {
    petition_get = urlBase + "/users" + val;
  } else if (key === "user-pk") {
    petition_get = urlBase + "/users/" + val;
    // "http://api.itpscorp.com/dev/crmui/agents/" + val;
  } else if (key === "quotePDF") {
    petition_get = urlBase + "/quotes/" + val + "/pdf";
    // "http://api.itpscorp.com/dev/crmui/agents/" + val;
  } else if (key === "reset-password-user") {
    petition_get = urlBase + "/users/" + val + "/reset-password";
    // "http://api.itpscorp.com/dev/crmui/agents/" + val;
  } else if (key === "contact_email") {
    petition_get = urlBase + "/search?contact_email=" + val;
    // "http://api.itpscorp.com/dev/crmui/agents/" + val;
  } else if (key === "agentpayouts") {
    petition_get = urlBase + "/agentpayouts";
    // "http://api.itpscorp.com/dev/crmui/agents/" + val;
  } else if (key === "agentcommissions") {
    petition_get = urlBase + "/agentcommissions";
    // "http://api.itpscorp.com/dev/crmui/agents/" + val;
  } else {
    petition_get = urlBase + "/" + key + "/" + val;
  }
  // var config = {
  //   headers: {
  //     Authorization: "Bearer " + token.authToken.replace(/['"]+/g, ""),
  //   },
  // };

  // return interceptor.get(petition_get, config);
  console.log(petition_get)
  return interceptor.get(petition_get);
  // } else {
  //   return <Redirect exact from="/" to="/logout" />;
  // }
}
