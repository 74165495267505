/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, withRouter } from "react-router-dom";
import ModalCustomer from "../../ModalCustomer/ModalCustomer"
import petition_user from "../../../../app/pages/home/Customer_Lookup/account_details/petition_user"
const QuickActionsDropdownToggle = React.forwardRef((props, ref) => {
  return (
    <a
      ref={ref}
      href="#"
      onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
      }}
      id=""
      className="btn btn-danger kt-subheader__btn-options"
    >
      Quick Actions
    </a>
  );
});

class QuickActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      data : [],
      selectRadio: null,
      loading: false
    }
  }

  
    componentDidMount() {
           petition_user("viewAccounts", "")
      .then(({ data: { result } }) => {

          this.setState({data : result,loading:true})
          if(result.length === 1){
            this.setState({selectRadio: result[0].pk})
          }
        
      /*   if (result.agent_id != null) {
          this.setState({ modalShow: true });
        }
        this.setState({
          allData: result,
          data: result,
          // loading: false,
        }); */
      })
             .catch((error) => {
                  this.setState({loading:true})
        console.log(error);
      });
    }

  activeModal = () => {
    if (this.state.data.length === 1) {
      return this.props.history.push(`quotes?accountName=${this.state.data[0].name}&pk=${this.state.data[0].pk}`)      
    }
    this.setState({
      modal: true
    })
  }
   offModal = () => {
    this.setState({
      modal: false
    })
  }


  render() {

        let changeRadio = (e) => {
    this.setState({selectRadio: e.target.id})
  }

      let OpenUrl = (e) => {

        const contact = this.state.data.find(element =>  element.pk === parseInt(this.state.selectRadio))
        this.offModal()
        
        if (window.location.pathname === "/quotes") {
         this.props.history.replace(`quotes?accountName=${contact.name}&pk=${contact.pk}`)
          window.location.reload() 
        } else {
           this.props.history.push(`quotes?accountName=${contact.name}&pk=${contact.pk}`)
        }
  }

    return (
      <>
        <ModalCustomer OpenUrl={OpenUrl} changeRadio={changeRadio} loading={this.state.loading} selectRadio={this.state.selectRadio} data={this.state.data} offModal={this.offModal} show={this.state.modal} /> 
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="quick-actions-tooltip">Quick actions</Tooltip>}
        >
          <Dropdown className="dropdown-inline" drop="down" alignRight>
            <Dropdown.Toggle
              as={QuickActionsDropdownToggle}
              id="dropdown-toggle-quick-actions-subheader"
            />

            <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
              <Link
                className="dropdown-item"
                to="./Newquote"
              >
                <i className="la la-plus"></i> New Customer Quote
              </Link>

              <Link onClick={() => {
                this.activeModal()
              }} className="dropdown-item">
                <i className="la la-plus"></i> Existing Customer Quote
              </Link>
              {/* <div className="dropdown-divider"></div> */}
              {/* <a className="dropdown-item">
                <i className="la la-cog"></i> Settings
              </a> */}
            </Dropdown.Menu>
          </Dropdown>
        </OverlayTrigger>
      </>
    );
  }
}


export default withRouter(QuickActions)